@import '~normalize.css';
@import "../resources/fonts/line-awesome-1.1.0/v1.0.1/css/line-awesome.min.css";
@import '~antd/dist/antd.css';
@import "variables";
@import "./table";

* {
  box-sizing: border-box
}

h3 {
  margin: 10px 0;
}

.App {
  background-color: #f4f0e9;
}

@font-face {
  font-family: CircularBold;
  src: url("../resources/fonts/Circular/circular-std-bold.ttf"),
  url("../resources/fonts/Circular/circular-std-bold.woff")
}

@font-face {
  font-family: CircularBook;
  src: url("../resources/fonts/Circular/new/circular-std-book.ttf"),
  url("../resources/fonts/Circular/new/circular-std-book.woff")
}

@font-face {
  font-family: CircularMedium;
  src: url("../resources/fonts/Circular/circular-std-medium.ttf"),
  url("../resources/fonts/Circular/circular-std-medium.woff")
}

.t-capital {
  text-transform: capitalize;
}

.t-uppercase {
  text-transform: uppercase !important;
}

.t-normal {
  text-transform: none !important;
}

.c-pointer {
  cursor: pointer;
}

.heading-primary {
  font-size: 18px;
  color: $primary_black;
  font-family: $boldFont;
  margin-bottom: 2px;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left !important;
}

.w-100 {
  width: 100%;
}

.mt-0 {
  margin-top: 0 !important;
}

.cross-text {
  text-decoration: line-through !important;
}

.heading-secondary {
  font-size: 18px;
  color: $primary_black;
  font-family: $bookFont;
  margin-bottom: 2px;
}

.heading-blue {
  font-size: 14px;
  color: #4D7CFE;
  font-family: $bookFont;
  margin-bottom: 5px;
  margin-top: 20px;
}

.heading-red__bold-primary {
  font-size: 25px;
  color: $primary_red;
  font-family: $boldFont;
  text-align: center;
  margin-top: 10px;
}

.heading-red__bold-secondary {
  font-size: 22px;
  color: $primary_red;
  font-family: $boldFont;
  text-align: center;
  margin-top: 10px;
}

.heading-red__book {
  font-size: 25px;
  color: $primary_red;
  font-family: CircularBook;
  text-align: center;
  margin-top: 10px;
}

.small-paragraph {
  font-size: 12px;
  margin-top: 10px;
  color: #343F4D;
  font-family: $bookFont;
}

.container-with-red-border {
  border: 1px solid $primary_red;
  padding: 10px;
  width: 100%;
}

.button-small-container {
  width: 100px;
  margin: 0 auto;
}

//NESTED LIST

ol.nested-list {
  list-style-type: none;
  counter-reset: item;
  margin: 0;
  padding: 0;

  li {
    margin-bottom: 15px;
  }

  ol:not([type='a']) {
    list-style-type: none;
    counter-reset: item;
    padding-left: 0;

    li {
      counter-increment: item;
      margin-bottom: 15px;
    }
  }

  & > li {
    display: table;
    counter-increment: item;

    &:before {
      content: counters(item, ".") ". ";
      display: table-cell;
      padding-right: 5px;
    }
  }

  li ol:not([type='a']) > li {

    &:before {
      content: counters(item, ".") ".  ";
    }
  }

  li.with-letter {
    ol {
      padding-left: 40px;
    }

    > div {
      display: flex;

      > span {
        padding-right: 5px;
      }
    }

    &:before {
      content: none !important;

    }

  }
}

@media (max-width: 380px) {
  .heading-primary {
    font-size: 15px;
  }

  .heading-secondary {
    font-size: 15px;
  }
  .heading-red__bold-primary {
    font-size: 22px;
  }
}

.ant-modal {
  top: 50px !important;
}

// ANTD BUTTONS OVERRIDES
.ant-btn {
  height: 35px;
  min-width: min-content;
  box-shadow: none;
  background: $primary_black;
  line-height: 1;
  font-family: $bookFont;
  font-size: 12px;
  font-weight: 300;
  color: #fff;
  vertical-align: middle;
  border-color: $primary_black;

  &:hover, &:focus {
    background: $primary_black;
    border-color: $primary_black;
    color: #fff;
    opacity: 0.8;
  }

  &[disabled] {
    color: #fff !important;
    background: $primary_black !important;
    border-color: $primary_black !important;
    opacity: 0.5 !important;

    &:hover {
      background: $primary_black !important;
      border-color: $primary_black !important;
      color: #fff !important;
    }
  }

  &.padding {
    padding-left: 30px;
    padding-right: 30px;
  }

  & > * {
    vertical-align: middle;
  }

  & > .anticon {
    font-size: 1.5em;

    & > svg, & > .la {
      display: block;
    }
  }

  & > span:first-child:last-child {
    display: block;
  }

  &.outline {
    background: none;
    color: $primary_black;

    &[disabled] {
      color: $primary_black;

      &:hover {
        background: none;
        color: $primary_black;
      }
    }
  }

  &.ant-btn-lg {
    height: 40px;
    font-size: 14px;

    &.padding {
      padding-left: 35px;
      padding-right: 35px;
    }
  }

  &.ant-btn-sm {
    height: 30px;
    font-size: 12px;

    &.padding {
      padding-left: 24px;
      padding-right: 24px;
    }
  }

  &.ant-btn-dashed {
    background: none;
    color: $primary_black;
  }

  &.ant-btn-primary {
    background: $secondary_blue;
    border-color: $secondary_blue;
    color: #fff;
    text-shadow: none;

    &.outline {
      background: none;
      color: $secondary_blue;
    }
  }

  &.ant-btn-danger {
    background: $primary_red;
    border-color: $primary_red;

    &[disabled] {
      background: $primary_red;
      border-color: $primary_red;

      &:hover {
        background: $primary_red;
        border-color: $primary_red;
      }
    }
  }

  &.ant-btn-link {
    height: auto;
    background: none;
    border: none;
    color: $secondary_blue;
  }

  &.fullwidth {
    min-width: 100%;
  }
}

@media (max-width: 330px) {
  .ant-btn {
    font-size: 11px;
  }
}

.ant-btn.mini, .mini > .ant-btn {
  height: 20px;
  font-size: 10px;

  & > .anticon {
    font-size: 1.3em;

    & + span {
      margin-left: 4px;
    }
  }

  &.padding {
    padding-left: 24px;
    padding-right: 24px;
  }
}

.ant-avatar-circle {
  background: #efefef;

  .anticon-user {
    color: $primary_red;
  }
}
