@import "variables";

.ant-table-wrapper.custom {
    .ant-table-body {
        overflow: auto;
    }

    table {
        min-width: 100%;
        width: max-content;
        border-collapse: separate;
        border-spacing: 0 15px;
        font-family: $bookFont;

        tr, tr > td {
            border-bottom: none;
            vertical-align: top;
        }

        tr {
            &.electrical, &.plumbing, &.locksmith, &.other {
                > td:first-child {
                    text-transform: capitalize;
                    padding-left: 50px;
                    background-repeat: no-repeat;
                    background-position: 10px center;
                }
            }

            &.electrical {
                > td:first-child {
                    border-color: $primary_red;
                    background-image: url(../resources/images/svg/icon-lamp.svg);
                }
            }

            &.plumbing {
                > td:first-child {
                    border-color: #4d7cfe;
                    background-image: url(../resources/images/svg/icon-plumbing.svg);
                }
            }

            &.locksmith {
                > td:first-child {
                    border-color: #35bd98;
                    background-image: url(../resources/images/svg/icon-key.svg);
                }
            }

            &.other {
                > td:first-child {
                    background-size: auto 50%;
                    border-left-color: #DEDEDE;
                    background-image: url(../resources/images/svg/other-types.svg);
                }
            }
        }

        td {
            padding: 15px 5px;
            background-color: #fff;
            border-radius: 0;

            &:first-child {
                border-left: 1px solid $border_color;
            }

            .label {
                display: block;
                margin-bottom: 10px;
                font-size: 10px;
                color: #98a9bc;
                line-height: 1;
            }

            .value {
                font-size: 12px;
                color: $primary_black;
            }
        }

        tr:hover td {
            background-color: #fff;
        }
    }
}

.table__row-with-border{
    table {
        //td:first-child {
        //    border-left-color: #4d7cfe;
        //}

        td {
            border-top: 1px solid $primary_Light_grey !important;
            border-bottom: 1px solid $primary_Light_grey !important;
        }

        td:last-child {
            border-right: 1px solid $primary_Light_grey;
        }
    }
}

.table__actions-container {
    display: flex;
    justify-content: flex-end;

    > .table__action {
        padding: 0 20px;
        height: 20px;

        i {
            font-size: 23px;
            color: #b9b8b8;
            cursor: pointer;

            &.la-trash {
                color: $primary_red;
            }
            &.la-ban {
                color: $primary_red;
            }
        }
    }

    > .table__action:not(:first-child) {
        border-left: 1px solid $primary_grey;
    }
}

.admin-table-container, .roles-table-container {
    table {
        //td:first-child {
        //    border-left-color: #4d7cfe;
        //}

        td {
            border-top: 1px solid $primary_Light_grey;
            border-bottom: 1px solid $primary_Light_grey !important;
        }

        td:last-child {
            border-right: 1px solid $primary_Light_grey;
        }
    }

}
