$primary_red: var(--primary-color, #F93A73);
$primary_black: #343F4D;
$primary_grey: #DFDFDF;
$primary_Light_grey: #f7f7f7;
$primary_cream: #F4F0E9;

$secondary_orange: #F93A73;
$secondary_green: #343F4D;
$secondary_blue: #4d7cfe;
$secondary_grey: #979797;

$border_color: #DEDEDE;

$backgroundApp: #f4f0e9;

%boldFont {
  font-family: CircularBook;
}

$boldFont: CircularBold;
$bookFont: CircularBook;
$mediumFont: CircularMedium;
