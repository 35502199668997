.congratulation-page__container {
  padding: 15px 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .heading-red__bold-secondary{
    margin-bottom: 25px;
    text-align: center;
  }
  .heading-secondary{
    margin-top: 25px;
    text-align: center;
  }
  .image{
    margin: 0;
    width: 300px;
    border-radius: 4px;
    box-shadow: none;
  }
}

.connection_footer{
  flex-direction: column;
  padding: 20px;
  height: auto;
  .btn-wrapper{
    width: 100%;
    max-width: 100%;
    margin-right: 0!important;
    margin-bottom: 15px;
  }
}

@media (min-width: 576px) {
  .congratulation-page__container {
    padding: 15px 30px;
    .image{
      width: 380px;
      box-shadow: none;
    }
  }

  .connection_footer{
    padding: 0;
    flex-direction: row;
    height: 100%;
    .btn-wrapper{
      width: auto;
      max-width: 200px;
      margin-right: 10px!important;
    }
  }
}
